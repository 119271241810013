
import {
  onMounted,
  onUnmounted,
  defineComponent,
  inject,
  reactive,
  ref,
  watch
} from "vue";
import { PageParams } from "@/apis/interface/PageParams";
import { RespBodyPager } from "@/apis/interface/Resp";
import {
  ICompany,
  CompanyFilter,
  CompanyModel,
  MoreCompnayInfoModel
} from "@/apis/interface/ICompany";
import bus from "@/utils/bus";
import { ElMessage } from "element-plus";
import DialogEditMore from "./DialogEditMore.vue";

export default defineComponent({
  name: "List",
  components: {
    DialogEditMore: DialogEditMore
  },
  setup() {
    const apiCompany = inject("apiCompany") as ICompany;
    const dialogEditMore = ref(null);
    const pageParams: PageParams = reactive({
      aaIndex: 1,
      aaSize: 50
    });

    const filter: CompanyFilter = reactive({
      uid: "",
      companyName: "",
      companyNameFormer: "",
      provCode: "",
      cityCode: "",
      countyCode: "",
      townCode: "",
      legalPerson: ""
    });

    let clearFilter = () => {
      filter.uid = "";
      filter.companyName = "";
      filter.companyNameFormer = "";
      filter.provCode = "";
      filter.cityCode = "";
      filter.countyCode = "";
      filter.townCode = "";
      filter.legalPerson = "";
      pageParams.aaIndex = 1;
      loadData();
    };

    let dataList = ref<CompanyModel[]>([]);
    let loading = ref(false);

    onUnmounted(() => {
      bus.off("on-table-row-edit-ok");
    });

    let loadData = async () => {
      loading.value = true;
      dataList.value = [];
      const resp = await apiCompany.getList(pageParams, filter);
      const pager: RespBodyPager<CompanyModel> = resp;
      pageParams.aaSize = pager.object.pageSize;
      pageParams.aaIndex = pager.object.pageCurrent;
      pageParams.total = pager.object.totalCount;
      dataList.value = pager.object.listData;
      // console.log("==>", dataList);
      loading.value = false;
    };

    let clickCreate = () => {
      bus.emit("on-table-row-edit", {
        dataList: [],
        dataRow: new CompanyModel({})
      });
    };

    let statusFormat = (status: number) => {
      // console.log(status);
      return "正常";
    };

    let deleteRow = async (id: number) => {
      let result = await apiCompany.remove([id]);
      if (result.object > 0) {
        ElMessage({
          type: "success",
          message: "刪除成功！"
        });
        await loadData();
      } else
        ElMessage({
          type: "error",
          message: "刪除失敗"
        });
    };

    let handleEditMore = (row: CompanyModel) => {
      (dialogEditMore.value as any).setData(row);
    };

    watch(
      () => pageParams.aaIndex,
      () => {
        loadData();
      }
    );
    watch(
      () => pageParams.aaSize,
      () => {
        pageParams.aaIndex = 1;
        loadData();
      }
    );

    onMounted(() => {
      loadData();
      bus.on("on-table-row-edit-ok", () => {
        loadData();
      });
    });

    let tableHeight = ref(0);

    return {
      loading,
      tableHeight,
      pageParams,
      filter,
      dataList,
      statusFormat,
      clickCreate,
      loadData,
      deleteRow,
      dialogEditMore,
      handleEditMore,
      clearFilter
    };
  }
});
